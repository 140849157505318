html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea {
  margin: 0;
  padding: 0;
  font-family: 'Gotham A', 'Gotham B', 'futura-pt', 'futura', 'Arial',
    sans-serif;
  letter-spacing: 0.05em;
  line-height: 1.2em;
}

html {
  height: 100%;
}

body {
  position: relative;
  margin: 0;
  min-height: 100%;
  padding-bottom: 100px;
  background-color: white;
}

body,
#root {
  overflow-x: hidden;
}

div,
li,
ul,
ol,
img,
a {
  margin: 0;
  padding: 0;
  border: none;
}

img {
  display: block;
  width: 100%;
  max-width: 100%;
}

a,
button {
  touch-action: manipulation;
}

canvas {
  width: 100%;
  border: none;
  display: block;
}

.share-buttons {
  text-align: center;
  /*vertical-align: top;*/
}
.share-buttons .button {
  margin-right: 5px;
  margin-left: 5px;
}

.footer {
  visibility: visible;
  position: absolute;
  bottom: 0;
  color: #ccc;
  text-align: center;
  left: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 10px;
  width: 100%;
}
.footer .terms {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}
.footer .terms a {
  color: #ccc;
  text-decoration: none;
}
.footer .terms a:hover {
  text-decoration: underline;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(59, 59, 59, 0.8); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensure it sits above the graphic */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Light border */
  border-top: 4px solid white; /* Top border is solid for the spin effect */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
